import './index.css';

function App() {
  return (
    <div className="App">
      <div className='header'>
        pixelaxis
      </div>
      <hr />
      <div>
        <i>We provided web design solutions in the early 2000s...</i>
      </div>
    </div>
  );
}

export default App;
